.adyen-checkout__dropin,
.adyen-checkout__dropin *,
.adyen-checkout__dropin *:after,
.adyen-checkout__dropin *:before {
  box-sizing: border-box; }

.adyen-checkout__payment-methods-list--loading {
  user-select: none;
  pointer-events: none; }

.adyen-checkout__instant-payment-methods-list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .adyen-checkout__instant-payment-methods-list li:not(:last-child) {
    margin-bottom: 8px; }

/* Forms */
.adyen-checkout__link {
  color: #06f;
  text-decoration: none; }

.adyen-checkout__link:hover {
  text-decoration: underline; }

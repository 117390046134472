.adyen-checkout__payment-method {
  position: relative;
  background: #fff;
  border: 1px solid #e6e9eb;
  cursor: pointer;
  margin-top: -1px;
  width: 100%;
  transition: opacity 0.3s ease-out; }

.adyen-checkout__payment-method:focus {
  outline: 0; }

.adyen-checkout__payment-method:first-child,
.adyen-checkout__payment-method--selected + .adyen-checkout__payment-method {
  margin-top: 0;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px; }

.adyen-checkout__payment-method:last-child,
.adyen-checkout__payment-method--next-selected {
  margin-bottom: 0;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px; }

.adyen-checkout__payment-method--loading {
  opacity: 0.2; }

.adyen-checkout__payment-method--selected.adyen-checkout__payment-method--loading {
  opacity: 0.9; }

.adyen-checkout__payment-method--confirming .adyen-checkout__payment-method__details__content {
  pointer-events: none;
  user-select: none; }

.adyen-checkout__payment-method--disabling {
  opacity: 0.3;
  pointer-events: none;
  user-select: none; }

.adyen-checkout__payment-method__header {
  align-items: center;
  color: #00112c;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1em;
  padding: 12px;
  padding-left: 44px;
  padding-right: 16px;
  position: relative;
  transition: background 0.1s ease-out;
  width: 100%; }
  [dir='rtl'] .adyen-checkout__payment-method__header {
    padding: 12px;
    padding-right: 44px; }
  .adyen-checkout__payment-method--standalone .adyen-checkout__payment-method__header {
    padding: 16px; }

.adyen-checkout__payment-method__header__title {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-right: 16px;
  max-width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  padding: 4px;
  color: #00112c;
  font-size: 1em;
  font-weight: 400; }
  [dir='rtl'] .adyen-checkout__payment-method__header__title {
    margin-right: 0;
    margin-left: 16px; }

.adyen-checkout__payment-method__surcharge {
  color: #687282;
  margin-left: 5px; }

.adyen-checkout__payment-method--selected {
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, opacity 0.3s ease-out;
  background: #f7f8f9;
  border: 1px solid #e6e9eb;
  margin: 8px 0;
  border-radius: 12px;
  cursor: default; }

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__header {
  flex-wrap: wrap; }

.adyen-checkout__payment-method__name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.adyen-checkout__payment-method__name--selected {
  font-weight: 500; }

.adyen-checkout__payment-method__details {
  padding: 0 16px 0;
  position: relative; }

.adyen-checkout__payment-method__details__content {
  margin: 0 0 16px; }

.adyen-checkout__payment-method__image__wrapper {
  height: 26px;
  width: 40px;
  position: relative; }

.adyen-checkout__payment-method__image__wrapper--outline:after {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  border-radius: 3px;
  border: 1px solid rgba(0, 27, 43, 0.17); }

.adyen-checkout__payment-method__image {
  display: block;
  border-radius: 3px; }

.adyen-checkout__payment-method__brands {
  display: flex;
  flex-wrap: wrap;
  margin: 4px 0;
  height: 16px;
  flex-basis: auto;
  flex-shrink: 1;
  text-align: right;
  overflow: hidden; }
  .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__brand-number {
    color: #99A3AD;
    font-size: 13px; }

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__brands {
  text-align: left;
  overflow: visible;
  height: auto; }

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper {
  display: inline-block;
  margin-right: 4px;
  height: 16px;
  width: 24px;
  transition: opacity 0.2s ease-out; }

.adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper:last-child {
  margin: 0; }

.adyen-checkout__payment-method--selected .adyen-checkout__payment-method__brands .adyen-checkout__payment-method__image__wrapper {
  margin-bottom: 4px; }

.adyen-checkout__payment-method__brands img {
  width: 24px;
  height: 16px; }

.adyen-checkout__payment-method__image__wrapper--disabled {
  opacity: 0.25; }

/*  Payment Method Radio Button */
.adyen-checkout__payment-method__radio {
  position: absolute;
  background-color: #fff;
  border: 1px solid #b9c4c9;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  left: 16px;
  transition: border-color 0.2s ease-out, box-shadow 0.2s ease-out; }
  [dir='rtl'] .adyen-checkout__payment-method__radio {
    right: 16px;
    left: auto; }
  .adyen-checkout__payment-method--standalone .adyen-checkout__payment-method__radio {
    display: none; }

.adyen-checkout__payment-method__radio:after {
  content: '';
  display: block;
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 50%;
  height: 6px;
  width: 6px;
  background-color: #fff;
  border-radius: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease-out; }

.adyen-checkout__payment-method:hover:not(.adyen-checkout__payment-method--selected) .adyen-checkout__payment-method__radio {
  border-color: #99a3ad;
  box-shadow: 0 0 0 2px #d4d9db;
  cursor: pointer; }

.adyen-checkout__payment-method__radio--selected {
  background-color: #06f;
  border: 0;
  transition: all 0.3s ease-out; }

.adyen-checkout__payment-method__radio--selected:hover {
  box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.4); }

.adyen-checkout__payment-method__radio--selected:after {
  transform: translateY(-50%) scale(1); }
